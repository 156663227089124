import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.pages = window.JAM.pages || {};

window.JAM.pages.subscribe = (() => {
  const PAGE_SELECTOR = ".app-subscribe";

  const init = () => {
    const $page = $(PAGE_SELECTOR);

    if (!$page.length) {
      return;
    }

    JAM.components.common.showLoadersOnFormSubmit();
    JAM.components.fetchCustomerMsisdn.init();
  };

  return {
    init,
  };
})();

$(function () {
  window.JAM.pages.subscribe.init();
});
