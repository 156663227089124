import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.components = window.JAM.components || {};

window.JAM.components.fetchCustomerMsisdn = (() => {
  const ENCRYPTED_MSISDN_INPUT_SELECTOR = "#encrypted_msisdn_input";
  const MSISDN_INPUT_SELECTOR = "#msisdn_input";

  const init = (alwaysSubmit = false) => {
    JAM.components.common.showLoader();

    const $pageData = $("#page-data");
    const partnerId = $pageData.data("dot-partner-id");

    const xhr = new XMLHttpRequest();

    xhr.open(
      "GET",
      `${JAM.vars.DOT_API_URL}/appgw/GetPartnerHEMSISDN?partnerId=${partnerId}`,
      true
    );

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          const responseJson = JSON.parse(xhr.responseText);
          const responseCode = responseJson["errorCode"];

          if (responseCode == 0) {
            const msisdn = responseJson["encMSISDN"];

            if (msisdn) {
              handleFetchMsisdnSuccess(msisdn);
            } else {
              handleFetchMsisdnFailure(alwaysSubmit);
            }
          } else {
            handleFetchMsisdnFailure(alwaysSubmit);
          }
        } else {
          // Oh no! There has been an error with the request!
          handleFetchMsisdnFailure(alwaysSubmit);
        }
      }
    };

    xhr.send();
  };

  const handleFetchMsisdnSuccess = (msisdn) => {
    $(ENCRYPTED_MSISDN_INPUT_SELECTOR).val(msisdn);
    $(MSISDN_INPUT_SELECTOR).removeAttr("required");

    const token = document.getElementsByName("csrf-token")[0].content;

    $.ajax({
      url: `${window.location.pathname}/track_visit`,
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": token,
      },
      data: {
        encrypted_msisdn: msisdn,
      },
      complete: () => {
        submitForm();
        JAM.components.common.hideLoader();
      },
    });
  };

  const handleFetchMsisdnFailure = (alwaysSubmit) => {
    if (alwaysSubmit) {
      submitForm();
      JAM.components.common.hideLoader();
      return;
    }

    $(MSISDN_INPUT_SELECTOR).removeClass("d-none");

    JAM.components.common.hideLoader();

    window.JAM.components.common.showFlashMessage(
      "Please enter your mobile number",
      "success"
    );
  };

  const submitForm = () => {
    $(ENCRYPTED_MSISDN_INPUT_SELECTOR)
      .parent("form")
      .find("input[type=submit")
      .trigger("click");
  };

  return {
    init,
  };
})();
